<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>Mammography</h1>
            <p>
              Mammography adalah tes untuk mendeteksi kanker payudara
              menggunakan sinar x. Dengan alat ini dokter dapat melakukan
              diagnosa atau screening 1-3 tahun lebih awal.
            </p>
            <br />

            <p style="font-weight: bold">Screening Mammography</p>

            <p>
              Screening mammography digunakan untuk diagnosa kanker payudara yg
              belum memiliki gejala,benjolan atau keluhan apapun. Tujuannya
              adalah untuk mengetahui lebih awal sebelum ada gejala klinis.
            </p>
            <br />

            <p style="font-weight: bold">Diagnosa Mammography</p>

            <p>
              Diagnosa mammography dilakukan bagi wanita yang memiliki keluhan
              seperti benjolan, rasa sakit, perubahan permukaan kulit, penebalan
              pada putting susu atau perubahan bentuk puting susu. Juga
              digunakan untuk evaluasi dari temuan di screening mammogram.
            </p>
            <br />
            <p style="font-weight: bold">Apa yang harus disiapkan ?</p>

            <p>
              Sebaiknya test dilakukan pada saat payudara anda dalam kondisi
              lembut. Waktu ideal adalah seminggu setelah menstruasi. Biasanya
              payudara menjadi keras seminggu sebelum dan pada saat haid.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/mamo.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "Mammography RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
